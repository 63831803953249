
export function ConfigureRealBackend({ endpoint }) {
    let realFetch = window.fetch;
    let users = [
	{
	    id: 1,
	    username: 'test',
	    password: 'test',
	    firstName: 'Test',
	    lastName: 'User'
	}
    ];
    window.fetch = function(url, opts) {
	const isLoggedIn = ((opts.headers['Authorization']||'').indexOf('Bearer') > -1);
	console.log('ConfigureBackend.fetch('+url+',', opts)
	return new Promise((resolve, reject) => {
	    if (url.endsWith('/users/authenticate') && opts.method === 'POST') {
		const params = JSON.parse(opts.body);
		const user = users.find((x) => x.username === params.username && x.password === params.password);
		if (!user) return error('Username or password is incorect');

		const { id, username, firstName, lastName, token } = user;
		return ok({ id, username, firstName, lastName, token })
	    }
	    // get users
	    if (url.endsWith('/users') && opts.method === 'GET') {
		if (!isLoggedIn) return unauthorized();
		return ok(users);
	    }
	    // pass through other requests
	    realFetch(url, opts).then((response) => resolve(response));

	    // helper funcs
	    function ok(body) {
		resolve({ ok: true, text: () => Promise.resolve(JSON.stringify(body)) })
	    }
	    function unauthorized() {
		resolve({ status: 401, text: () => Promise.resolve(JSON.stringify({
		    message: 'Unauthorized'
		})) })
	    }
	    function error(message) {
		resolve({ status: 400, text: () => Promise.resolve(JSON.stringify({ message })) })
	    }
	})
    };
}
